import React, { useContext, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { CORE_API_URL } from "../../../../config";
import { AppContext } from "../../../context/app-context";
import { DeriveError } from "../../../utils/derive-error";
import { navigate } from "gatsby-link";
import mixpanel from "mixpanel-browser";
export default function LoginCard() {
  const [user, setUser] = useState({});
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const { setUser: setU } = context;
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            Remember to copy the Botion{" "}
            <a
              href="https://api.botion.so/template"
              target="_blank"
              className="text-indigo-500"
            >
              template
            </a>{" "}
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                type="email"
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                type="password"
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack>
              <Button
                className="bg-indigo-900 hover:bg-indigo-800 text-white"
                isLoading={loading}
                onClick={async () => {
                  mixpanel.track("Clicked login on login page");

                  try {
                    setLoading(true);
                    const response = await axios.post(
                      `${CORE_API_URL}/login`,
                      {
                        user,
                      },
                      {
                        headers: {
                          "Content-Type": "application/x-www-form-urlencoded",
                        },
                      }
                    );
                    console.log(response.data.user);
                    localStorage.setItem(
                      "user",
                      JSON.stringify(response.data.user)
                    );
                    await setU(response.data.user);
                    navigate("/dashboard");
                  } catch (error) {
                    const err = DeriveError(error);
                    toast({
                      title: "Login error.",
                      description: err,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
