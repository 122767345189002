import React from "react";
import LoginCard from "../components/organisms/login-card/login-card";
import WithSubnavigation from "../components/organisms/navigation/navigation-bar-chakra";
import Header from "../components/organisms/vpn-template/header";

const Login = () => {
  return (
    <>
      <Header />
      <LoginCard />
    </>
  );
};

export default Login;
